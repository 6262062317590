import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationInterceptor } from './services/authentication.interceptor';
import { RequestService } from './services/request.service';
import { OwlNotifierModule, OwlSwitchModule, OwlTooltipModule } from 'owl-ng';
import { ToastService } from './services/toast.service';
import { LocalStorageService } from 'ngx-store';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RenderSliderComponent } from './components/render-slider/render-slider.component';
import { TimerProviderService } from './providers/timer-provider.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TimerLoadComponent } from './components/component-timer/timer.component';
import { ImageInputComponent } from './components/image-input/image-input.component';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CommonModule,
        HttpClientModule,
        OwlNotifierModule,
        OwlSwitchModule,
        OwlTooltipModule,
        NgxSpinnerModule
    ],
    declarations: [
        SidebarComponent,
        NavbarComponent,
        RenderSliderComponent,
        TimerLoadComponent,
        ImageInputComponent
    ],
    providers: [
        RequestService,
        ToastService,
        LocalStorageService,
        TimerProviderService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true, },
    ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    HttpClientModule,
    OwlNotifierModule,
    SidebarComponent,
    NavbarComponent,
    RenderSliderComponent,
    TimerLoadComponent,
    ImageInputComponent
  ]
})
export class SharedModule { }
