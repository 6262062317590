import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'PhoenixMobile';

  constructor(private localStorageService: LocalStorageService) { }

  ngOnInit() {
    let isMobile = /Android|iPhone/i.test(window.navigator.userAgent);
    if (isMobile) {
      console.log(isMobile, 'мобильник');

      // this.localStorageService.set('is_mobile', isMobile);
    }
    this.localStorageService.set('is_mobile', isMobile);

  }

}
