import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const MAX_IMAGE_SIZE = 1048576;

@Injectable(
    {providedIn: 'root'}
)
export class CompressorService {
    isSafari = false;

    constructor() {
    }

    compress(file: File): Observable<any> {
        const width = 1200;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return Observable.create(observer => {
            const userAgent = navigator.userAgent.toLowerCase();
            if (userAgent.includes('safari') && !userAgent.includes('chrome') && !userAgent.includes('android')) {
                this.isSafari = true;
            }
            reader.onload = ev => {
                const img = new Image();
                img.src = (ev.target as any).result;
                (img.onload = async () => {
                    try {
                        let needCompress = true;
                        let quality = 1;
                        const elem = document.createElement('canvas');
                        const scaleFactor = width / img.width;
                        const imageWidth = img.width > width ? width : img.width;
                        const imageHeight = img.width > width ? img.height * scaleFactor : img.height;
                        elem.width = imageWidth;
                        elem.height = imageHeight;
                        const ctx = elem.getContext('2d') as CanvasRenderingContext2D;
                        ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
                        while (needCompress && quality >= 0.1) {
                            const blob = await this.getCanvasBlob(elem, quality);
                            if (blob.size < MAX_IMAGE_SIZE) {
                                needCompress = false;
                                observer.next(
                                    new File([blob], file.name, {
                                        type: this.isSafari ? 'image/png' : 'image/webp',
                                        lastModified: Date.now(),
                                    }),
                                );
                            } else {
                                if (quality <= 0.1 && !this.isSafari) {
                                    observer.error(
                                        new Error('File too big')
                                    );
                                }
                                else if (this.isSafari) {
                                    observer.error(
                                        new Error('Safari does not allow you to compress the file')
                                    );
                                }
                            }
                            quality = Number((quality - 0.02).toFixed(2));
                        }
                    } catch (err) {
                        observer.error(err);
                    }
                }),
                    (reader.onerror = error => observer.error(error));
            };
        });
    }

    getCanvasBlob(canvas: HTMLElementTagNameMap['canvas'], quality): Promise<Blob> {
        return new Promise( (resolve, reject) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, this.isSafari ? 'image/png' : 'image/webp', parseFloat(quality));
            canvas.onerror = error => reject(error);
        });
    }
}
