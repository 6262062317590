import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation, OnDestroy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-render-slider',
  templateUrl: './render-slider.component.html',
  styleUrls: ['./render-slider.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class RenderSliderComponent implements OnChanges, OnDestroy {

  @Input() event;
  @Output() eventChange = new EventEmitter();
  private urls = [];
  private count: number = -1;
  private sliderPage: Array<any> = [];
  private select: number = 0;
  arr: Array<string> = [];

  constructor() { }

  ngOnDestroy() {
    this.arr = [];
    this.sliderPage = [];
    this.count = -1;
    this.select = 0;
    this.event = undefined;
    this.eventChange.emit(this.event);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof changes['event'] !== 'undefined' && changes['event'].currentValue instanceof Event) {
      try {
        this.count = -1;
        if (this.event.target.files && this.event.target.files[0]) {
          let filesAmount = this.event.target.files.length;
          for (let i = 0; i < filesAmount / 3; i++) {
            this.urls.push([]);
          }
          this.sliderPage = this.split(this.urls, 3);
          this.sliderPage.map(arr => {
            for (let j = 0; j < this.event.target.files.length; j++) {
              if (j % 3 === 0) {
                this.count++;
              }
              arr[this.count].push(this.event.target.files[j]);
            }
            arr.map(item => {
              for (let i = 0; i < item.length; i++) {
                let reader = new FileReader();
                reader.onload = (event) => {
                  item[i] = event.target['result'];
                };
                reader.readAsDataURL(item[i]);
              }
            });
          });
          this.arr = this.sliderPage[0][this.select];
        }
      } catch (e) {
        console.log(e, 'error');
        this.urls = [];
      }
    }
  }

  split(arr, n) {
    let res = [];
    while (arr.length) {
      res.push(arr.splice(0, n));
    }
    return res;
  }

  prev() {
    console.log('prev');
    if (this.select === 0) {
      this.select = this.sliderPage[0].length - 1;
    } else {
      this.select--;
    }
    this.arr = this.sliderPage[0][this.select];
  }

  next() {
    console.log('next');
    if (this.select === this.sliderPage[0].length - 1) {
      this.select = 0;
    } else {
      this.select++;
    }
    this.arr = this.sliderPage[0][this.select];
  }
}
