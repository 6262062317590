import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TimerProviderService } from '../../providers/timer-provider.service';

@Component({
  selector: 'app-timer-load',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerLoadComponent implements OnInit {

  @Input() startSpinning: string;

  constructor(
    private spinner: NgxSpinnerService,
    public dataTimer: TimerProviderService) {

  }

  ngOnInit() {
    this.dataTimer.currentTimer.subscribe(data => {
      console.log(data);
      if (data) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
  }
}
