import { Component, OnInit, HostListener } from "@angular/core";
import { LocalStorageService } from "ngx-store";
import { Router } from "@angular/router";

declare interface RouteInfo {
    path: string;
    title: string;
    class: string;
    // access: Array<number> | Array<string>;
    bange?: number;
    icon?: string;
    image?: string;
    dropdown?: RouteInfo[];
}

export const ROUTES: RouteInfo[] = [
    // { path: 'news-management', title: 'Пользователи', icon: '', class: '' },
    { path: 'news-management', title: 'Новости', icon: 'far fa-newspaper', class: '' },
    { path: 'merchant', title: 'Мерчант', icon: 'fas fa-gifts', class: '' },
    { path: 'user-management', title: 'Пользователи', icon: 'far fa-user-circle', class: '' },
    { path: 'study-materials', title: 'Учебные материалы', icon: 'fas fa-graduation-cap', class: '' },
    { path: 'oil', title: 'Асортимент масла', icon: 'fas fa-oil-can', class: '' },
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    menuItems: any[];
    avatar: string;
    user: any;
    open: boolean = false;
    box: boolean = false;
    isMobile: boolean;
    element: any;
    content: any;
    checkbox: any;

    constructor(
        private router: Router,
        private localStorageService: LocalStorageService
    ) {
        this.user = this.localStorageService.get('user');
        this.isMobile = this.localStorageService.get('is_mobile');
    }

    ngOnInit() {
        console.log(window.navigator.language, ' language');
        this.element = document.getElementById('user-content');
        this.content = document.getElementById('all-content');
        this.checkbox = document.getElementById('checkbox');
        this.menuItems = ROUTES.filter(menuItem => {
            return menuItem;
        });
        if (this.isMobile) this.fixedContent(false);
    }

    logout() {
        this.localStorageService.clear();
        localStorage.clear();
        this.router.navigate(['auth/sign-in']);
    }

    boxContent() {
        this.open = true;
        if (!this.box) {
            this.element.classList.add('open');
        } else {
            this.element.classList.remove('open');
        }
        this.fixedContent(!this.box);
        this.seeBox();
    }

    fixedContent(open: boolean) {
        if (open) {
            this.content.classList.add('open-content');
        } else {
            this.content.classList.remove('open-content');
        }
    }

    seeBox() {
        if (this.box) {
            this.checkbox.classList.add('see-box');
        } else {
            this.checkbox.classList.remove('see-box');
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (event.target.innerWidth <= 768) {
            this.box = false;
            this.open = true;
            this.content.classList.remove('open-content');
        } else if (!this.element.classList.value.includes('open')) {
            if (!this.box) {
                this.open = false;
            }
            this.seeBox();
        } else if (event.target.innerWidth > 768 && this.element.classList.value.includes('open')) {
            this.content.classList.add('open-content');
            this.checkbox.classList.remove('see-box');
            this.box = false;
        }
    }

    @HostListener('mouseenter') onMouseEnter() {
        if (!this.isMobile) {
            if (!this.open) {
                this.element.classList.add('open');
            }
        }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (!this.isMobile) {
            if (!this.open) {
                this.element.classList.remove('open');
            }
        }
    }
}
