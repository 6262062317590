import {Injectable} from '@angular/core';
import {Request} from '../interfaces/request.interface';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import * as queryString from 'query-string';
import {tap} from 'rxjs/operators';
import {ToastService} from './toast.service';

@Injectable()
export class RequestService implements Request {

    constructor(private http: HttpClient, private router: Router, private toastService: ToastService) {
    }

    public get(url: string, body: object = null) {
        if (body !== null) {
            if (Object.keys(body).length > 0) {
                url += '?' + queryString.stringify(body);
            }
        }
        return this.http.get(url)
            .pipe(tap(() => {
            }, error => {
                this.toastService.toast(error, 'error');
            }));
    }

    public post(url: string, credentials: any) {
        return this.http.post(url, credentials)
            .pipe(tap(() => {
            }, error => {
                if (error.error && error.error.errors && (error.error.errors.partner_id || error.error.errors.dealer_id)) {
                    error.error.errors.partner_id ? this.toastService.toast(error.error.errors.partner_id[0], 'error')
                        : this.toastService.toast(error.error.errors.dealer_id[0], 'error');
                } else {
                    this.toastService.toast(error, 'error');
                }
            }));
    }

    public put(url: string, credentials: any) {
        return this.http.put(url, credentials)
            .pipe(tap(() => {
            }, error => {
                this.toastService.toast(error, 'error');
            }));
    }

    public delete(url: string) {
        return this.http.delete(url)
            .pipe(tap(() => {
                this.toastService.toast('Данные успешно удалены', 'success');
            }, error => {
                this.toastService.toast(error, 'error');
            }));
    }

    public getFile(url: string, body: object = null) {
        // this.ngProgress.start();
        if (body !== null) {
            if (Object.keys(body).length > 0) {
                url += '?' + queryString.stringify(body);
            }
        }
        return this.http.get(url, {responseType: 'text'})
            .pipe(tap(() => {
                // this.ngProgress.done();

            }, error => {
                this.toastService.toast(error, 'error');
            }));
    }
}
