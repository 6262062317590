import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-store';
import { DataTitleService } from '../../providers/data-title.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public title: string;

    constructor(
        private router: Router,
        private data: DataTitleService,
        public localStorageService: LocalStorageService) {
    }

    logout() {
        this.localStorageService.clear();
        localStorage.clear();
        this.router.navigate(['auth/sign-in']);
    }

    ngOnInit() {
        this.data.currentTitle.subscribe(title => {
            this.title = title;
        });
    }

    toggleButton() {
        let el = document.getElementById('user-content');
        if (el.classList.value.includes('open')) {
            el.classList.remove('open');
        } else {
            el.classList.add('open');
        }
    }

}