import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TimerProviderService {

    private timerSource = new BehaviorSubject(false);
    currentTimer = this.timerSource.asObservable();

    constructor() { }

    changeTimer(timerInfo: boolean) {
        this.timerSource.next(timerInfo);
    }
}