import { Injectable } from "@angular/core";
import { OwlNotifierService } from 'owl-ng';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class ToastService {


    private config: object = {};
    constructor(private notifier: OwlNotifierService, private router: Router) { }

    // /**
    //    *
    // * {number} id: ID for the notifier. If omitted, a unique one will be generated.
    //    * {number} life: The length of time in milliseconds to wait before automatically dismissing
    //    * the notifier. Default is 0, and the notifier would not be automatically dismissed.
    //    * {number} maxStack: Defines maximum number of notifiers can be shown on the page at the same time.
    //    * {string | string[]} notifierClass: The extra class or classes for the notifier.
    //    * {'question' | 'warning' | 'info' | 'success' | 'error'} type: Defines the type of the notifier. Each type would have
    //    * a corresponding icon and theme color.
    //    * {'top'|'bottom'} verticalPosition: Defines the vertical position of the notifier.
    //    * {'left' | 'center' | 'right'} horizontalPosition: Defines the horizontal position of the notifier
    //    */
    toast(data: any, type: any) {
        console.log(data, 'error');
        if (window.innerWidth <= 768) {
            this.config = {
                maxStack: 1,
                verticalPosition: 'top',
                horizontalPosition: 'center',
            };
        } else {
            this.config = {
                maxStack: 4,
                verticalPosition: 'top',
                horizontalPosition: 'right',
            };
        }
        this.config['type'] = type;
        if (data instanceof HttpErrorResponse) {
            if (data['error'] instanceof Object) {
                for (let item in data['error']) {
                    this.config['life'] = 0;
                    this.notifier.open(data['error'][item][0], 'Ок', this.config);
                }
            }
            if (data['error']['errors'] instanceof Object) {
                for (let item in data['error']['errors']) {
                    this.config['life'] = 0;
                    this.notifier.open(data['error']['errors'][item][0], 'Ок', this.config);
                }
            }
            if (typeof data['error']['message'] === 'string') {
                this.config['life'] = 0;
                this.notifier.open(data['error']['message'], 'Ок', this.config);
            }
            if (data.status === 401) {
                this.router.navigate(['auth/sign-in']);
            }
        } else {
            this.config['life'] = 5000;
            this.notifier.open(data, 'Ok', this.config);
        }
    }
}
