import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { expand, map } from 'rxjs/operators';
import { CompressorService } from '../../services/compressor.service';
import { EMPTY } from 'rxjs';

@Component({
    selector: 'app-image-input',
    templateUrl: './image-input.component.html',
    styleUrls: ['./image-input.component.scss'],
})
export class ImageInputComponent {
    @Input() label: string = 'Добавить файл';
    @Input() componentId: string;
    @Output() imageSelect = new EventEmitter<any>();
    public compressedImages = [];
    public imageSizeError = false;
    public safariSizeError = false;
    public imageLoading = false;

    constructor(
        private compressor: CompressorService
    ) {
    }

    propagateOnChange(value) {
        try {
            this.imageLoading = true;
            if (value.target.files.length) {
                const files = value.target.files;
                const compress = this.recursiveCompress(files[0], 0, files).pipe(
                    expand(res => {
                        return res.index > res.array.length - 1
                            ? EMPTY
                            : this.recursiveCompress(files[res.index], res.index, files);
                    }),
                );
                compress.subscribe(res => {
                    this.imageLoading = false;
                    if (res.index > res.array.length - 1) {
                        this.imageSelect.emit(this.compressedImages);
                        this.compressedImages = [];
                    }
                }, error => {
                    this.imageLoading = false;
                    if (error.message === 'File too big') {
                        this.imageSizeError = true;
                    } else {
                        this.safariSizeError = true;
                    }
                });
            }
        } catch (err) {
            console.log('err', err);
        }
    }

    recursiveCompress(image: File, index, array) {
        return this.compressor.compress(image).pipe(
            map(response => {
                this.compressedImages.push(response);
                return {
                    data: response,
                    index: index + 1,
                    array,
                };
            }),
        );
    }
}
